<article>
  <p-panel [toggleable]="true">
    <ng-template pTemplate="header">
      <h1 class="typo-h4 text-n-100">
        @if (profiel.status !== 'VERWIJDERD') {
          {{
            'user-page.profile-type'
              | translate
                : {
                    type: profiel.type | professioneelPersoonlijkTranslationKey | translate,
                  }
          }}
        } @else {
          {{ 'user-page.profile-type-verwijderd' | translate }}
        }
      </h1>
    </ng-template>

    <div class="flex flex-col gap-y-4">
      <div class="space-y-2">
        <h2 class="typo-body variant-body-fat text-n-100">
          {{ 'user-page.profile' | translate }}
        </h2>

        <p>{{ profiel.voornaam }} {{ profiel.naam }}</p>

        <p-tag class="block" severity="secondary" [value]="profiel.id" />
      </div>

      <div
        class="rounded bg-[var(--gray-100)] p-4 flex flex-col gap-y-4 border-[1px] border-solid border-[var(--gray-300)]"
      >
        <div class="flex flex-col gap-y-4">
          @if (profiel.status !== 'VERWIJDERD') {
            <form [formGroup]="form" class="flex flex-col gap-y-4">
              <div class="flex align-items-center">
                <p-radioButton [value]="true" formControlName="geactiveerd" inputId="activate" />

                <label class="ml-2" for="activate">
                  {{
                    'user-page.activate'
                      | translate
                        : {
                            type: profiel.type | professioneelPersoonlijkTranslationKey | translate,
                          }
                  }}
                </label>
              </div>

              <div class="flex align-items-center">
                <p-radioButton [value]="false" formControlName="geactiveerd" inputId="deactivate" />

                <label class="ml-2" for="deactivate">
                  {{
                    'user-page.deactivate'
                      | translate
                        : {
                            type: profiel.type | professioneelPersoonlijkTranslationKey | translate,
                          }
                  }}
                </label>
              </div>
            </form>
          } @else {
            <p>{{ 'user-page.profiel-verwijderd' | translate }}</p>
          }
        </div>
      </div>
    </div>
  </p-panel>
</article>
