import { Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { DefaultProbleemDto } from 'parkour-beheer-app-dto';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'default-melding-content',
  templateUrl: './default-melding-content.component.html',
  standalone: true,
  imports: [PanelModule, TranslateModule],
})
export class DefaultMeldingContentComponent {
  @Input({ required: true }) melding!: DefaultProbleemDto;
}
