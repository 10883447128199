export const environment = {
  production: false,
  API_BASE_URL: '',
  opentelemetry: {
    enabled: false,
    tracingUrl: '',
  },
  mockGlobalHeader: false,
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/792a5589-6695-47cc-8903-10f6aebee764',
};
