<page-layout>
  <h1 class="typo-h1" header>{{ 'users-page.title' | translate }}</h1>

  <div class="space-y-4" content>
    <h2 class="typo-h3">{{ 'users-page.overview' | translate }}</h2>

    <div>
      <div class="flex items-center gap-x-4">
        <form (ngSubmit)="search()" [formGroup]="searchForm" class="flex-1">
          <p-iconField class="block" iconPosition="right">
            <input
              [placeholder]="'users-page.input-placeholder' | translate"
              class="w-full"
              formControlName="rrn"
              pInputText
            />

            @if (isSearchLoading) {
              <p-inputIcon styleClass="pi pi-spinner pi-spin" />
            } @else {
              <button (click)="search()">
                <p-inputIcon styleClass="pi pi-search" />
              </button>
            }
          </p-iconField>
        </form>

        <p-button
          (click)="clear()"
          [label]="'buttons.clear' | translate"
          [outlined]="true"
          icon="pi pi-filter-slash"
        />
      </div>

      @if (searchForm.get('rrn')?.hasError('invalidRijksregisternummer')) {
        <small class="text-negative">
          Ongeldig rijksregisternummer. Het moet 11 cijfers bevatten.
        </small>
      }
    </div>

    <p-panel class="block">
      <ng-template pTemplate="header">
        <h3 class="typo-body variant-body-fat">
          {{ 'users-page.title' | translate }}
        </h3>
      </ng-template>

      @if (profielen$ | async; as profielen) {
        <p-table
          (onRowSelect)="goToDetail($event)"
          [lazy]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          [lazyLoadOnInit]="false"
          [value]="profielen"
          dataKey="id"
          class="block"
          selectionMode="single"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 15%">
                {{ 'users-page.table.rrn' | translate }}
              </th>

              <th style="width: 55%">
                {{ 'users-page.table.name' | translate }}
              </th>

              <th style="width: 15%">
                {{ 'users-page.table.profileType' | translate }}
              </th>

              <th style="width: 15%">
                {{ 'users-page.table.lastUpdate' | translate }}
              </th>
            </tr>
          </ng-template>

          <ng-template let-profiel pTemplate="body">
            @if (isGebruikersLoading) {
              <tr>
                <td>
                  <p-skeleton />
                </td>

                <td>
                  <p-skeleton />
                </td>

                <td>
                  <p-skeleton />
                </td>

                <td>
                  <p-skeleton />
                </td>
              </tr>
            } @else {
              <tr [pSelectableRow]="profiel">
                <td>{{ profiel.rrn }}</td>

                <td>{{ profiel.voornaam }} {{ profiel.naam }}</td>

                <td>
                  {{ profiel.type | professioneelPersoonlijkTranslationKey | translate }}
                </td>

                <td>{{ profiel.laatsteUpdate | date: 'd/MM/yyyy' }}</td>
              </tr>
            }
          </ng-template>
        </p-table>
      } @else {
        <p>{{ 'users-page.empty' | translate }}</p>
      }
    </p-panel>
  </div>
</page-layout>
