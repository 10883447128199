import { Component } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  templateUrl: './login.page.html',
  standalone: true,
  imports: [PanelModule, TranslateModule, RouterLink],
})
export class LoginPage {
  constructor(private readonly userService: AuthService) {}

  login() {
    this.userService.login();
  }
}
