<p-panel [toggleable]="true">
  <ng-template pTemplate="header">
    <h1 class="typo-h4 text-n-100">{{ 'notification-page.history' | translate }}</h1>
  </ng-template>

  <div>
    <p-table
      [sortOrder]="-1"
      [tableStyle]="{ 'min-width': '30rem' }"
      [value]="historiekItems"
      class="block"
      sortField="aangemaaktOpTijdstip"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50%">
            {{ 'notification-page.table.action' | translate }}
          </th>

          <th pSortableColumn="aangemaaktOpTijdstip" style="width: 25%">
            {{ 'notification-page.table.date' | translate }}

            <p-sortIcon field="aangemaaktOpTijdstip" />
          </th>

          <th style="width: 25%">
            {{ 'notification-page.table.administrator' | translate }}
          </th>
        </tr>
      </ng-template>

      <ng-template let-item pTemplate="body">
        <tr>
          <td>{{ getActionsTranslationKey(item.actie) | translate: translationParams }}</td>

          <td>{{ item.aangemaaktOpTijdstip | date: 'd/MM/yyyy HH:mm' }}</td>

          <td>{{ item.auteur }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>
