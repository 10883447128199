import { ProbleemHistoriekItemDto, ProfielHistoriekItemDto } from 'parkour-beheer-app-dto';

export const actionTranslationLabels: Record<
  (ProfielHistoriekItemDto | ProbleemHistoriekItemDto)['actie'],
  string
> = {
  BERICHT_AUTOMATISCH_GEMASKEERD: 'actions.auto-masking',
  BERICHT_MANUEEL_GEMASKEERD: 'actions.manual-masking',
  BERICHT_MANUEEL_ONTHULD: 'actions.manual-reveal',
  GEACTIVEERD: 'actions.activated',
  GEDEACTIVEERD: 'actions.deactivated',
};
