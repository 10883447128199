export const probleemTypes = [
  {
    type: 'FOUT_GEBRUIKER',
    key: 'melding-types.fout-gebruiker',
  },
  {
    type: 'FOUT_PARKOUR',
    key: 'melding-types.fout-parkour',
  },
  {
    type: 'OPMERKING',
    key: 'melding-types.opmerking',
  },
  {
    type: 'BERICHT',
    key: 'melding-types.bericht',
  },
];
