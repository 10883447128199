<article>
  <p-panel [toggleable]="true">
    <ng-template pTemplate="header">
      <h1 class="typo-h4 text-n-100">{{ 'notification-page.content-title' | translate }}</h1>
    </ng-template>

    <div class="flex flex-col gap-y-4">
      <div class="space-y-2">
        <h2 class="typo-body variant-body-fat text-n-100">
          {{ 'notification-page.reason' | translate }}
        </h2>

        <p>{{ getMeldingReasonTranslationKey(melding.reden) | translate }}</p>
      </div>

      <div class="space-y-2">
        <h2 class="typo-body variant-body-fat text-n-100">
          {{ 'notification-page.description' | translate }}
        </h2>

        <p>{{ melding.beschrijving }}</p>
      </div>

      <proof-card [bericht]="melding.gerapporteerdBericht">
        @switch (melding.gerapporteerdBericht.type) {
          @case ('TEKST') {
            <text-message [bericht]="melding.gerapporteerdBericht" />
          }
          @case ('ATTACHMENT') {
            <file-message [bericht]="melding.gerapporteerdBericht" />
          }
          @case ('SPRAAK') {
            <file-message [bericht]="melding.gerapporteerdBericht" />
          }
        }
      </proof-card>

      <form [formGroup]="form" class="flex flex-col gap-y-4">
        <div class="flex align-items-center">
          <p-radioButton [value]="true" formControlName="gemaskeerd" inputId="masking" />

          <label class="ml-2" for="masking">
            {{ 'notification-page.bericht-masking' | translate }}
          </label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton [value]="false" formControlName="gemaskeerd" inputId="reveal" />

          <label class="ml-2" for="reveal">
            {{ 'notification-page.bericht-reveal' | translate }}
          </label>
        </div>
      </form>
    </div>
  </p-panel>
</article>
