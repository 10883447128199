<div
  class="rounded bg-[var(--gray-100)] p-4 flex flex-col gap-y-4 border-[1px] border-solid border-[var(--gray-300)]"
>
  <div class="flex justify-between flex-wrap gap-4">
    @if (bericht.profiel; as profiel) {
      <div class="flex flex-col">
        <span class="typo-body variant-body-small">Verzonden door:</span>

        <div class="flex flex-col gap-y-1">
          <span class="typo-body variant-body-fat text-n-100">
            {{ profiel.voornaam }} {{ profiel.naam }}
          </span>

          @if (profiel.status !== 'VERWIJDERD' && profiel.type === 'PROFESSIONEEL_TEAMLID') {
            <span
              ><i class="pi pi-verified mr-2"></i
              >{{ 'notification-page.professioneel' | translate }}</span
            >
          } @else {
            <span>{{ 'notification-page.persoonlijk' | translate }}</span>
          }

          <p-tag
            [style]="{ 'background-color': 'var(--gray-300)' }"
            [value]="profiel.id"
            class="block"
            severity="secondary"
          />
        </div>
      </div>
    }
  </div>

  <ng-content></ng-content>

  <div class="flex gap-2 justify-between items-center">
    <p class="typo-body variant-body-small">
      {{
        'notification-page.bericht-timestamp'
          | translate
            : {
                timeDate: bericht.timestamp | date: 'HH:mm - dd/MM/yyyy',
              }
      }}
    </p>

    @if (bericht.type === 'ATTACHMENT' || bericht.type === 'SPRAAK') {
      <a
        class="p-button p-button-text"
        target="_blank"
        [download]="decodeURIComponent(bericht.bestand.filename)"
        [href]="getDownloadLink(bericht.bestand.fileId)"
      >
        <span class="p-button-icon pi pi-download p-button-icon-left"></span>

        <span class="p-button-label">{{ 'buttons.download' | translate }}</span>
      </a>
    }
  </div>
</div>
