import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, tap } from 'rxjs';

import { UserDto } from 'parkour-beheer-app-dto';
import { User } from '../model/user';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  private _user$ = new ReplaySubject<User>(1);

  constructor(
    private httpClient: HttpClient,
    private readonly router: Router,
  ) {}

  retrieveSessionUser() {
    this.httpClient
      .get<UserDto>(`${environment.API_BASE_URL}/api/users/current`)
      .pipe(
        tap((user) => this._user$.next(user)),
        tap((user) => {
          if (user.rol === 'ANONIEM') {
            this.router.navigate(['/']);
          }
        }),
      )
      .subscribe();
  }

  public login() {
    const redirectUrl =
      this.router.getCurrentNavigation()?.initialUrl.toString() ?? this.router.url;
    window.location.href = `${environment.API_BASE_URL}/login?redirect=${redirectUrl}`;
  }

  public logout() {
    window.location.href = `${environment.API_BASE_URL}/logout`;
  }

  onStartup() {
    this.retrieveSessionUser();
  }
}
