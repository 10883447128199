import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MaskingFormGroup } from '../pages/detail/detail.page';

@Injectable({
  providedIn: 'root',
})
export class MeldingFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createMaskingFormGroup(initialMaskedState: boolean = true): MaskingFormGroup {
    return this.formBuilder.nonNullable.group({
      gemaskeerd: this.formBuilder.nonNullable.control(initialMaskedState),
    });
  }
}
