import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { PreloadAllModules, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth/services/auth.service';
import { VoHeaderComponent } from './shared/ui/vo-header/vo-header.component';
import { ToastModule } from 'primeng/toast';
import { GlobalHttpErrorHandler } from './auth/global-http-error-handler';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterOutlet,
    AppRoutingModule,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    VoHeaderComponent,
    ToastModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'nl-BE',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: AuthService) => () => userService.onStartup(),
      deps: [AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorHandler,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('nl');
    translate.use('nl');
    registerLocaleData(localeNl, LOCALE_ID);
  }
}
