import { Component, OnInit } from '@angular/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { Button } from 'primeng/button';
import { map, Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'top-bar',
  standalone: true,
  templateUrl: './top-bar.component.html',
  imports: [TabMenuModule, Button, TranslateModule, AsyncPipe],
})
export class TopBarComponent implements OnInit {
  items$!: Observable<MenuItem[] | undefined>;

  constructor(
    private readonly translate: TranslateService,
    private readonly userService: AuthService,
  ) {}

  ngOnInit() {
    this.items$ = this.translate.get(['notifications-page.title', 'users-page.title']).pipe(
      map((translations) => [
        {
          label: translations['notifications-page.title'],
          icon: 'pi pi-exclamation-circle',
          routerLink: ['/meldingen'],
          routerLinkActiveOptions: { exact: false },
        },
        {
          label: translations['users-page.title'],
          icon: 'pi pi-users',
          routerLink: ['/gebruikers'],
          routerLinkActiveOptions: { exact: false },
        },
      ]),
    );
  }

  onLogoutClick() {
    this.userService.logout();
  }
}
