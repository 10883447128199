<page-layout>
  <h1 class="typo-h1" header>{{ 'notifications-page.title' | translate }}</h1>

  <div class="space-y-4" content>
    <h2 class="typo-h3">{{ 'notifications-page.overview' | translate }}</h2>

    <div class="flex items-center gap-x-4">
      <form (ngSubmit)="search()" [formGroup]="searchForm" class="flex-1">
        <p-iconField class="block" iconPosition="right">
          <input
            [placeholder]="'notifications-page.input-placeholder' | translate"
            class="w-full"
            formControlName="id"
            inputmode="numeric"
            pInputText
            type="number"
          />

          @if (isSearchLoading) {
            <p-inputIcon styleClass="pi pi-spinner pi-spin" />
          } @else {
            <button (click)="search()">
              <p-inputIcon styleClass="pi pi-search" />
            </button>
          }
        </p-iconField>
      </form>

      <p-button
        (click)="clear()"
        [label]="'buttons.clear' | translate"
        [outlined]="true"
        icon="pi pi-filter-slash"
      />
    </div>

    <p-panel class="block">
      <ng-template pTemplate="header">
        <h3 class="typo-body variant-body-fat">
          {{ 'notifications-page.title' | translate }}
        </h3>
      </ng-template>

      @if (meldingen$ | async; as meldingen) {
        <p-table
          (onLazyLoad)="loadMeldingen($event)"
          (onRowSelect)="goToDetail($event)"
          [paginator]="true"
          [lazy]="true"
          [rowsPerPageOptions]="[10, 20]"
          [rows]="rows"
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="meldingen.content"
          [totalRecords]="meldingen.totalElements"
          [first]="first"
          [sortOrder]="-1"
          [lazyLoadOnInit]="false"
          dataKey="id"
          sortField="aangemaaktOpTijdstip"
          class="block"
          selectionMode="single"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" style="width: 10%">
                {{ 'notifications-page.table.id' | translate }}

                <p-sortIcon field="id" />
              </th>

              <th pSortableColumn="type" style="width: 75%">
                {{ 'notifications-page.table.type' | translate }}

                <p-sortIcon field="type" />
              </th>

              <th pSortableColumn="aangemaaktOpTijdstip" style="width: 15%">
                {{ 'notifications-page.table.created' | translate }}

                <p-sortIcon field="aangemaaktOpTijdstip" />
              </th>
            </tr>
          </ng-template>

          <ng-template let-melding pTemplate="body">
            @if (isMeldingenLoading) {
              <tr>
                <td>
                  <p-skeleton />
                </td>

                <td>
                  <p-skeleton />
                </td>

                <td>
                  <p-skeleton />
                </td>
              </tr>
            } @else {
              <tr [pSelectableRow]="melding">
                <td>{{ melding.id }}</td>

                <td>{{ getMeldingTypeTranslationKey(melding.type) | translate }}</td>

                <td>{{ melding.aangemaaktOpTijdstip | date: 'd/MM/yyyy' }}</td>
              </tr>
            }
          </ng-template>
        </p-table>
      } @else {
        <p>{{ 'notifications-page.empty' | translate }}</p>
      }
    </p-panel>
  </div>
</page-layout>
