<page-layout class="block max-md:mb-30">
  <div class="flex items-center justify-between gap-x-4" header>
    <div class="space-y-4">
      <div class="flex items-center gap-x-6">
        @if (profielDetails$ | async; as profiel) {
          @if (profiel.status !== 'VERWIJDERD') {
            <a
              class="p-button p-button-link p-0"
              routerLink="/gebruikers"
              [queryParams]="{ query: profiel.rrn }"
            >
              <span class="p-button-icon p-button-icon-left pi pi-arrow-left"></span>

              <span class="p-button-label">{{ 'buttons.go-back-overview' | translate }}</span>
            </a>

            <span class="text-[var(--surface-200)]">|</span>

            <p-tag
              severity="info"
              styleClass="typo-body variant-body-fat variant-body-small"
              value="RRN: {{ profiel.rrn }}"
            />
          } @else {
            <a class="p-button p-button-link p-0" routerLink="/gebruikers">
              <span class="p-button-icon p-button-icon-left pi pi-arrow-left"></span>

              <span class="p-button-label">{{ 'buttons.go-back-overview' | translate }}</span>
            </a>
          }
        }
      </div>

      @if (profielDetails$ | async; as profiel) {
        <h1 class="typo-h1">
          {{
            'user-page.title'
              | translate
                : {
                    name: profiel.voornaam + ' ' + profiel.naam,
                  }
          }}
        </h1>
      } @else {
        <h1 class="typo-h1">
          {{
            'user-page.title'
              | translate
                : {
                    name: '',
                  }
          }}
        </h1>
      }
    </div>

    @if (profielDetails$ | async; as profiel) {
      <div
        class="max-md:fixed max-md:inset-x-0 max-md:z-10 max-md:bottom-0 max-md:w-full max-md:p-4 max-md:shadow-2 max-md:border-t-[1px] max-md:border-solid max-md:border-[var(--surface-200)] flex items-center gap-x-4 bg-n-1000"
      >
        @if (profiel.status !== 'VERWIJDERD') {
          <a
            class="max-md:w-full p-button p-button-outlined"
            routerLink="/gebruikers"
            [queryParams]="{ query: profiel.rrn }"
          >
            <span class="p-button-label"> {{ 'buttons.cancel' | translate }} </span>
          </a>

          <button class="max-md:w-full p-button" form="gebruiker-form">
            <span class="p-button-icon pi pi-save"></span>

            <span class="p-button-label ml-2"> {{ 'buttons.save' | translate }} </span>
          </button>
        }
      </div>
    }
  </div>

  <div content>
    <p-toast
      [breakpoints]="{ '768px': { width: 'calc(100% - 2rem)', bottom: '4.5rem', left: '1rem' } }"
      position="bottom-right"
    />

    @if (profielDetails$ | async; as profiel) {
      <form
        (ngSubmit)="onSubmit(profiel.id)"
        [formGroup]="gebruikerForm"
        class="grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[4fr_2fr] gap-10"
        id="gebruiker-form"
      >
        <div class="min-w-0 flex flex-col gap-y-10">
          <profile-card [form]="gebruikerForm.controls.activationSection" [profiel]="profiel" />

          <change-history
            [historiekItems]="profiel.historiekItems"
            [translationParams]="{
              professioneelLabel:
                profiel.status !== 'VERWIJDERD' && profiel.type === 'PROFESSIONEEL_TEAMLID'
                  ? 'Professioneel'
                  : 'Persoonlijk',
            }"
          />
        </div>
      </form>
    } @else {
      <p>{{ 'user-page.empty' | translate }}</p>
    }
  </div>
</page-layout>
