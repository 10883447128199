import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../../shared/ui/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { ProfileCardComponent } from '../../components/profile-card/profile-card.component';
import { ProfielDetailsDto as ProfielDetails, ProfielId } from 'parkour-beheer-app-dto';
import { ProfielService } from '../../services/profiel.service';
import { ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ChangeHistoryComponent } from '../../../shared/ui/change-history/change-history.component';

export type ActivatedFormGroup = FormGroup<{
  geactiveerd: FormControl<boolean>;
}>;

export type GebruikerForm = FormGroup<{
  activationSection: ActivatedFormGroup;
}>;

@Component({
  templateUrl: './detail.page.html',
  standalone: true,
  imports: [
    PageLayoutComponent,
    AsyncPipe,
    RouterLink,
    TagModule,
    TranslateModule,
    ToastModule,
    ProfileCardComponent,
    ReactiveFormsModule,
    ChangeHistoryComponent,
    ChangeHistoryComponent,
  ],
  providers: [MessageService],
})
export class DetailPage implements OnInit {
  readonly profielDetails$ = new ReplaySubject<ProfielDetails>(1);
  gebruikerForm!: GebruikerForm;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly profielService: ProfielService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
  ) {}

  ngOnInit() {
    this.retrieveProfielDetails();
  }

  retrieveProfielDetails() {
    const profielId = this.activatedRoute.snapshot.paramMap.get('id') as ProfielId;

    this.profielService.getProfielDetails(profielId).subscribe({
      next: (profiel) => {
        this.profielDetails$.next(profiel);

        this.gebruikerForm = this.formBuilder.nonNullable.group({
          activationSection: this.formBuilder.nonNullable.group({
            geactiveerd: this.formBuilder.nonNullable.control(profiel.status === 'ACTIEF'),
          }),
        });
      },
      error: (e) => {
        throw e;
      },
    });
  }

  onSubmit(profielId: ProfielId) {
    const formValues = this.gebruikerForm.getRawValue();

    if (this.gebruikerForm.valid) {
      const geactiveerd = formValues.activationSection.geactiveerd;

      if (geactiveerd) {
        this.profielService.activeerProfiel(profielId).subscribe({
          complete: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Aanpassingen bewaard',
            });

            this.retrieveProfielDetails();
          },
          error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Er ging iets fout bij het bewaren',
            });

            throw e;
          },
        });
      } else {
        this.profielService.deactiveerGebruiker(profielId).subscribe({
          complete: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Aanpassingen bewaard',
            });

            this.retrieveProfielDetails(); // Quick fix because adding the historiek takes a while
            setTimeout(() => this.retrieveProfielDetails(), 5000); // Quick fix because adding the historiek takes a while
          },
          error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Er ging iets fout bij het bewaren',
            });

            throw e;
          },
        });
      }
    }
  }
}
