<aside>
  @if (melding.rapporteerder | enrichRapporteerder | async; as rapporteerder) {
    <ul class="space-y-6">
      <li class="space-y-2 flex gap-x-4 items-baseline">
        <div class="w-[8rem]">
          <span class="typo-body text-[var(--gray-400)]">{{
            'notification-page.type' | translate
          }}</span>
        </div>

        <div class="flex-1">
          <p-tag
            [value]="getMeldingTypeTranslationKey(melding.type) | translate"
            severity="warning"
            styleClass="typo-body variant-body-fat variant-body-small"
          />
        </div>
      </li>

      <li class="space-y-2 flex gap-x-4 items-baseline">
        <div class="w-[8rem]">
          <span class="typo-body text-[var(--gray-400)]">{{
            'notification-page.timestamp' | translate
          }}</span>
        </div>

        <div class="flex-1">
          <span
            ><i class="pi pi-calendar-clock mr-2 align-baseline"></i
            >{{ melding.aangemaaktOpTijdstip | date: 'HH:mm - dd/MM/yyyy' }}</span
          >
        </div>
      </li>

      <li class="space-y-2 flex gap-x-4 items-baseline">
        <div class="w-[8rem]">
          <span class="typo-body text-[var(--gray-400)]">{{
            'notification-page.reporter' | translate
          }}</span>
        </div>

        <ul class="flex flex-col space-y-2 flex-1">
          <li>
            <span class="typo-body variant-body-fat">
              @switch (rapporteerder.type) {
                @case ('AANGEMELD') {
                  {{ rapporteerder.profiel.voornaam }} {{ rapporteerder.profiel.naam }}
                }
                @case ('ANONIEM') {
                  {{ rapporteerder.voornaam }} {{ rapporteerder.naam }}
                }
              }
            </span>
          </li>

          @if (rapporteerder.type === 'AANGEMELD') {
            <li>
              @if (
                rapporteerder.profiel.status !== 'VERWIJDERD' &&
                rapporteerder.profiel.type === 'PROFESSIONEEL_TEAMLID'
              ) {
                <span
                  ><i class="pi pi-verified mr-2"></i
                  >{{ 'notification-page.professioneel' | translate }}</span
                >
              } @else {
                <span>{{ 'notification-page.persoonlijk' | translate }}</span>
              }
            </li>

            <li>
              <p-tag class="block" severity="secondary" [value]="rapporteerder.profielId" />
            </li>
          }
        </ul>
      </li>

      <li class="space-y-2 flex gap-x-4 items-baseline">
        <div class="w-[8rem]">
          <span class="typo-body text-[var(--gray-400)]">
            {{ 'notification-page.contact-reporter' | translate }}
          </span>
        </div>

        <ul class="flex-1">
          @switch (rapporteerder.type) {
            @case ('AANGEMELD') {
              @if (rapporteerder.profiel.status !== 'VERWIJDERD' && rapporteerder.profiel.email) {
                <li>
                  <div class="flex items-center">
                    <i class="pi pi-envelope mr-2 align-middle"></i>

                    <a
                      class="underline"
                      [href]="'mailto:' + rapporteerder.profiel.email"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ rapporteerder.profiel.email }}
                    </a>
                  </div>
                </li>
              }
            }
            @case ('ANONIEM') {
              @if (rapporteerder.email) {
                <li>
                  <div class="flex items-center">
                    <i class="pi pi-envelope mr-2 align-middle"></i>

                    <a
                      class="underline"
                      [href]="'mailto:' + melding.rapporteerder.email"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ melding.rapporteerder.email }}
                    </a>
                  </div>
                </li>
              }
            }
          }

          @if (
            rapporteerder.type === 'AANGEMELD' &&
            rapporteerder.profiel.status !== 'VERWIJDERD' &&
            rapporteerder.profiel.telefoonnummer
          ) {
            <li>
              <div class="flex items-center">
                <i class="pi pi-phone mr-2 align-middle"></i>

                <a
                  class="underline"
                  [href]="'tel:' + rapporteerder.profiel.telefoonnummer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ rapporteerder.profiel.telefoonnummer }}
                </a>
              </div>
            </li>
          }
        </ul>
      </li>

      <li class="space-y-2 flex gap-x-4 items-baseline">
        <div class="w-[8rem]">
          <span class="typo-body text-[var(--gray-400)]">
            {{ 'notification-page.logging' | translate }}
          </span>
        </div>

        <ul class="list-disc flex-1 pl-4">
          @if (melding.metaData.os) {
            <li>
              @switch (melding.metaData.os) {
                @case ('ios') {
                  <span><i class="pi pi-apple mr-2"></i>{{ melding.metaData.os }}</span>
                }
                @case ('android') {
                  <span><i class="pi pi-android mr-2"></i>{{ melding.metaData.os }}</span>
                }
                @case ('web') {
                  <span><i class="pi pi-globe mr-2"></i>{{ melding.metaData.os }}</span>
                }
              }
            </li>
          }

          @if (melding.metaData.appVersion) {
            <li>
              <span>{{ melding.metaData.appVersion }}</span>
            </li>
          }

          @if (melding.metaData.osVersion) {
            <li>
              <span>{{ melding.metaData.osVersion }}</span>
            </li>
          }
        </ul>
      </li>
    </ul>
  }
</aside>
