<div class="container-space w-full h-screen flex items-center justify-center">
  <div class="flex flex-col items-center md:max-w-[20rem] w-full text-center">
    <span class="typo-h1">{{ 'login-page.title' | translate }}</span>

    <button class="p-button font-bold w-full block mt-8" (click)="login()">
      {{ 'buttons.login' | translate }}
    </button>

    <span class="text-p-100 typo-body variant-body-fat mt-20">{{ 'title' | translate }}</span>
  </div>
</div>
