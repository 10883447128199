import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TableLazyLoadEvent, TableModule, TableRowSelectEvent } from 'primeng/table';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ProbleemSortDirection, ProbleemSortProperty, ProblemenPage } from 'parkour-beheer-app-dto';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { PanelModule } from 'primeng/panel';
import { getMeldingTypeTranslationKey } from '../../../shared/utils';
import { PageLayoutComponent } from '../../../shared/ui/page-layout/page-layout.component';
import { MeldingenService } from '../../services/meldingen.service';

@Component({
  templateUrl: './overview.page.html',
  standalone: true,
  imports: [
    PageLayoutComponent,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    FormsModule,
    TableModule,
    Button,
    TranslateModule,
    SkeletonModule,
    ReactiveFormsModule,
    AsyncPipe,
    RouterLink,
    PanelModule,
    DatePipe,
  ],
})
export class OverviewPage implements OnInit, OnDestroy {
  meldingen$: Subject<ProblemenPage> = new ReplaySubject(1);
  searchForm!: FormGroup<{
    id: FormControl<number | string>;
  }>;
  isSearchLoading = false;
  isMeldingenLoading = false;
  pageNumber = 0;
  pageSize = 10;
  first = 0;
  rows = 10;
  searchTerm: string | number = '';
  sortProperty: ProbleemSortProperty = 'aangemaaktOpTijdstip';
  sortDirection: ProbleemSortDirection = 'DESC';
  protected readonly getMeldingTypeTranslationKey = getMeldingTypeTranslationKey;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly meldingenService: MeldingenService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      id: this.formBuilder.nonNullable.control<number | string>(''),
    });

    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((queryParams: ParamMap) => {
        if (queryParams.keys.length > 0) {
          this.first = (Number(queryParams.get('page')) ?? this.first) * this.pageSize;
          this.pageNumber = Number(queryParams.get('page'));
          this.rows = Number(queryParams.get('size')) ?? this.rows;
          this.pageSize = Number(queryParams.get('size'));
          this.searchTerm = queryParams.get('query') ? Number(queryParams.get('query')) : '';

          this.searchForm.patchValue({
            id: this.searchTerm,
          });

          this.getMeldingen(
            this.pageNumber,
            this.pageSize,
            this.sortProperty,
            this.sortDirection,
            String(this.searchTerm),
          );
        } else {
          this.setRouteQueryParams(this.pageNumber, this.pageSize, this.searchTerm, () => {
            this.getMeldingen(
              this.pageNumber,
              this.pageSize,
              this.sortProperty,
              this.sortDirection,
              String(this.searchTerm),
            );
          });
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToDetail(event: TableRowSelectEvent) {
    this.router.navigateByUrl(`/meldingen/${event.data.id}`);
  }

  clear() {
    const searchId = this.searchForm.value.id;

    if (searchId) {
      this.searchTerm = '';

      this.searchForm.reset();
      this.setRouteQueryParams(this.pageNumber, this.pageSize, this.searchTerm);
      this.searchMeldingen(
        this.pageNumber,
        this.pageSize,
        this.sortProperty,
        this.sortDirection,
        this.searchTerm,
      );
    }
  }

  search() {
    const searchId = this.searchForm.value.id;

    this.searchForm.markAllAsTouched();

    if (searchId) {
      this.isSearchLoading = true;
      this.isMeldingenLoading = true;
      this.searchTerm = searchId;
      this.pageNumber = 0;
      this.pageSize = 10;
      this.sortProperty = 'aangemaaktOpTijdstip';
      this.sortDirection = 'DESC';

      this.setRouteQueryParams(this.pageNumber, this.pageSize, this.searchTerm);
      this.searchMeldingen(
        this.pageNumber,
        this.pageSize,
        this.sortProperty,
        this.sortDirection,
        String(this.searchTerm),
      );
    } else {
      this.searchTerm = '';

      this.setRouteQueryParams(this.pageNumber, this.pageSize, this.searchTerm);
      this.searchMeldingen(
        this.pageNumber,
        this.pageSize,
        this.sortProperty,
        this.sortDirection,
        this.searchTerm,
      );
    }
  }

  loadMeldingen(event: TableLazyLoadEvent) {
    const { first, rows, sortField, sortOrder } = event;

    this.pageNumber = first ? Math.floor(first / this.pageSize) : 0;
    this.pageSize = rows ? rows : 10;
    this.sortProperty = sortField ? (sortField as ProbleemSortProperty) : 'aangemaaktOpTijdstip';
    this.sortDirection = sortOrder === 1 ? 'ASC' : 'DESC';

    this.setRouteQueryParams(this.pageNumber, this.pageSize, String(this.searchTerm));
    this.searchMeldingen(
      this.pageNumber,
      this.pageSize,
      this.sortProperty,
      this.sortDirection,
      String(this.searchTerm),
    );
  }

  setRouteQueryParams(
    pageNumber: number,
    pageSize: number,
    searchTerm?: string | number,
    callback?: () => void,
  ) {
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          page: pageNumber,
          size: pageSize,
          query: searchTerm,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => callback && callback());
  }

  searchMeldingen(
    pageNumber: number,
    pageSize: number,
    sortField: ProbleemSortProperty,
    sortOrder: ProbleemSortDirection,
    searchTerm?: string,
  ) {
    this.fetchMeldingen(pageNumber, pageSize, sortField, sortOrder, searchTerm)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (meldingenPage: ProblemenPage) => this.meldingen$.next(meldingenPage),
        complete: () => {
          this.isSearchLoading = false;
          this.isMeldingenLoading = false;

          this.searchForm.markAsPristine();
        },
        error: (e: unknown) => {
          throw e;
        },
      });
  }

  getMeldingen(
    pageNumber: number,
    pageSize: number,
    sortField: ProbleemSortProperty,
    sortOrder: ProbleemSortDirection,
    searchTerm?: string,
  ) {
    this.isMeldingenLoading = true;

    this.fetchMeldingen(pageNumber, pageSize, sortField, sortOrder, searchTerm)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (meldingenPage: ProblemenPage) => {
          this.meldingen$.next(meldingenPage);
        },
        complete: () => {
          this.isMeldingenLoading = false;
        },
        error: (e: unknown) => {
          throw e;
        },
      });
  }

  fetchMeldingen(
    pageNumber: number,
    pageSize: number,
    sortField: ProbleemSortProperty,
    sortOrder: ProbleemSortDirection,
    searchTerm: string = '',
  ) {
    return this.meldingenService.getMeldingen({
      pageNumber,
      pageSize,
      sortProperty: sortField,
      sortDirection: sortOrder,
      searchTerm,
    });
  }
}
