<page-layout class="block max-md:mb-30">
  <div class="flex items-center justify-between gap-x-4" header>
    <div class="space-y-4">
      <div class="flex items-center gap-x-6">
        <a class="p-button p-button-link p-0" routerLink="/meldingen">
          <span class="p-button-icon p-button-icon-left pi pi-arrow-left"></span>

          <span class="p-button-label">{{ 'buttons.go-back-overview' | translate }}</span>
        </a>

        <span class="text-[var(--surface-200)]">|</span>

        @if (melding$ | async; as melding) {
          <p-tag
            severity="info"
            styleClass="typo-body variant-body-fat variant-body-small"
            value="ID: {{ melding.id }}"
          />
        }
      </div>

      @if (melding$ | async; as melding) {
        <h1 class="typo-h1">
          {{
            'notification-page.title'
              | translate
                : {
                    type: getMeldingTypeTranslationKey(melding.type) | translate,
                  }
          }}
        </h1>
      } @else {
        <h1 class="typo-h1">
          {{
            'notification-page.title'
              | translate
                : {
                    type: '',
                  }
          }}
        </h1>
      }
    </div>

    @if (melding$ | async; as melding) {
      @if (melding.type === 'BERICHT') {
        <div
          class="max-md:fixed max-md:inset-x-0 max-md:z-10 max-md:bottom-0 max-md:w-full max-md:p-4 max-md:shadow-2 max-md:border-t-[1px] max-md:border-solid max-md:border-[var(--surface-200)] flex items-center gap-x-4 bg-n-1000"
        >
          <a class="max-md:w-full p-button p-button-outlined" [routerLink]="['..']">
            <span class="p-button-label"> {{ 'buttons.cancel' | translate }} </span>
          </a>

          <button class="max-md:w-full p-button" form="melding-form">
            <span class="p-button-icon pi pi-save"></span>

            <span class="p-button-label ml-2"> {{ 'buttons.save' | translate }} </span>
          </button>
        </div>
      }
    }
  </div>

  <div content>
    <p-toast
      [breakpoints]="{ '768px': { width: 'calc(100% - 2rem)', bottom: '4.5rem', left: '1rem' } }"
      position="bottom-right"
    />

    @if (melding$ | async; as melding) {
      <form
        (ngSubmit)="onSubmit(melding.id)"
        [formGroup]="meldingForm"
        class="grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[4fr_2fr] gap-10"
        id="melding-form"
      >
        <div class="min-w-0 flex flex-col gap-y-10">
          @if (melding.type === 'BERICHT') {
            <bericht-melding-content
              [melding]="melding"
              [form]="meldingForm.controls.maskingSection"
            />

            <change-history [historiekItems]="melding.historiekItems" />
          } @else {
            <default-melding-content [melding]="melding" />
          }
        </div>

        <melding-info [melding]="melding" />
      </form>
    } @else {
      <p>{{ 'notification-page.empty' | translate }}</p>
    }
  </div>
</page-layout>
