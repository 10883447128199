import { Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { getMeldingTypeTranslationKey } from '../../../shared/utils';
import { ProbleemDto } from 'parkour-beheer-app-dto';
import { EnrichRapporteerderPipe } from './enrich-rapporteerder.pipe';

@Component({
  selector: 'melding-info',
  templateUrl: './melding-info.component.html',
  standalone: true,
  imports: [PanelModule, TranslateModule, DatePipe, TagModule, EnrichRapporteerderPipe, AsyncPipe],
})
export class MeldingInfoComponent {
  @Input({ required: true }) melding!: ProbleemDto;

  protected readonly getMeldingTypeTranslationKey = getMeldingTypeTranslationKey;
}
