import { Pipe, PipeTransform } from '@angular/core';
import { ProfielType } from 'parkour-beheer-app-dto';

@Pipe({
  name: 'professioneelPersoonlijkTranslationKey',
  standalone: true,
})
export class ProfessioneelPersoonlijkTranslationKeyPipe implements PipeTransform {
  transform(profielType: ProfielType): string {
    return profielType === 'PROFESSIONEEL_TEAMLID'
      ? 'profiel-types.professioneel'
      : 'profiel-types.persoonlijk';
  }
}
