import { Component, Input, OnInit } from '@angular/core';
import { TekstBerichtDto } from 'parkour-beheer-app-dto';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import linkifyStr from 'linkify-string';

@Component({
  selector: 'text-message',
  templateUrl: './text-message.component.html',
  standalone: true,
  imports: [DatePipe],
})
export class TextMessageComponent implements OnInit {
  @Input({ required: true }) bericht!: TekstBerichtDto;

  safeHtmlContent!: SafeHtml;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
      linkifyStr(this.bericht.inhoud, {
        defaultProtocol: 'https',
        target: 'blank',
        className: 'break-all',
      }),
    );
  }
}
