export const probleemReasons = [
  {
    type: 'FRAUDE_BEDROG',
    key: 'melding-reasons.fraude-bedrog',
  },
  {
    type: 'HAATSPRAAK',
    key: 'melding-reasons.haatspraak',
  },
  {
    type: 'ONGEPAST',
    key: 'melding-reasons.ongepast',
  },
  {
    type: 'PESTEN',
    key: 'melding-reasons.pesten',
  },
  {
    type: 'ZELFDODING',
    key: 'melding-reasons.zelfdoding',
  },
  {
    type: 'ANDERE',
    key: 'melding-reasons.andere',
  },
];
