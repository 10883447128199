import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { map, Observable, take } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let vl: any;

@Component({
  selector: 'vo-header',
  standalone: true,
  templateUrl: './vo-header.component.html',
})
export class VoHeaderComponent implements OnInit {
  protected mock: boolean = environment.mockGlobalHeader;
  private isAuthenticated$: Observable<boolean> = this.userService.user$.pipe(
    map((user) => user.rol !== 'ANONIEM'),
  );
  private globalHeaderEmbedScript: HTMLScriptElement;
  private globalHeaderEmbedScriptSrc: string = environment.globalHeaderEmbedCode;

  constructor(private readonly userService: AuthService) {
    this.globalHeaderEmbedScript = document.createElement('script');
    this.globalHeaderEmbedScript.src = this.globalHeaderEmbedScriptSrc;
  }

  ngOnInit(): void {
    if (!this.mock) {
      this.isAuthenticated$.pipe(take(1)).subscribe((authenticated) => {
        this.bootstrapGlobalHeader();
        this.configureActivityTracking(authenticated);
        this.configureSessionExtensionBasedOnActivity();
        this.configureLogout();
      });
    }
  }

  private bootstrapGlobalHeader(): void {
    vl.widget.client
      .bootstrap(this.globalHeaderEmbedScriptSrc)
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((widget: any) => {
        return widget.setMountElement(document.getElementById('global-header')).mount();
      })
      // eslint-disable-next-line no-console
      .catch(console.error.bind(console));
  }

  private configureActivityTracking(userAuthenticated: boolean): void {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture((widget: any) => {
      if (widget.getPluginTypeId() === 'global_header') {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        widget.getExtension('citizen_profile.session').then((session: any) => {
          function activityEventHandler() {
            session.extend();
          }

          const eventNames = [
            'mousedown',
            'mousemove',
            'mousewheel',
            'DOMMouseScroll',
            'scroll',
            'wheel',
            'keydown',
            'keypress',
            'touchmove',
            'touchstart',
          ];
          for (let i = 0; i < eventNames.length; i++) {
            window.addEventListener(eventNames[i], activityEventHandler);
          }

          session.configure({
            active: userAuthenticated,
            endpoints: {
              loginUrl: '/login',
              loginRedirectUrl: '/login-succesvol',
              logoutUrl: '/logout',
            },
          });
        });
      }
    });
  }

  private configureSessionExtensionBasedOnActivity() {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture(function (widget: any) {
      if (widget.getPluginTypeId() === 'global_header') {
        widget.on('citizen_profile.session.extend', function () {
          // eslint-disable-next-line no-console
          console.debug('Received session extend request');
        });
      }
    });
  }

  private configureLogout(): void {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture(function (widget: any) {
      if (widget.getPluginTypeId() === 'global_header') {
        widget.on(
          'citizen_profile.session.logout.request',
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (logoutRequest: any) {
            logoutRequest.acknowledge();
            switch (logoutRequest.getRequest().getReason()) {
              case 'expired':
              case 'inactivity':
                fetch('/api/profiel/me')
                  .then(function (response) {
                    if (response.status === 200) {
                      logoutRequest.reject();
                    } else {
                      logoutRequest.accept();
                    }
                  })
                  .catch(function () {
                    logoutRequest.accept();
                  });
                break;

              case 'user':
                logoutRequest.accept();
                break;

              default:
                logoutRequest.reject();
                break;
            }
          },
        );
      }
    });
  }
}
