import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { BerichtDto, FileId } from 'parkour-beheer-app-dto';
import { AsyncPipe, DatePipe } from '@angular/common';
import { FileService } from '../../services/file.service';
import { ProfielPipe } from '../../../profiel.pipe';

@Component({
  selector: 'proof-card',
  templateUrl: './proof-card.component.html',
  standalone: true,
  imports: [TagModule, Button, TranslateModule, DatePipe, ProfielPipe, AsyncPipe],
})
export class ProofCardComponent {
  @Input({ required: true }) bericht!: BerichtDto;

  constructor(private readonly fileService: FileService) {}

  getDownloadLink(fileId: FileId) {
    return this.fileService.getDownloadLink(fileId);
  }

  protected readonly decodeURIComponent = decodeURIComponent;
}
