import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProfielDetailsDto, ProfielId, ProfielOverviewDto } from 'parkour-beheer-app-dto';

@Injectable({
  providedIn: 'root',
})
export class ProfielService {
  constructor(private httpClient: HttpClient) {}

  getProfielen(rrn: string): Observable<ProfielOverviewDto[]> {
    return this.httpClient.get<ProfielOverviewDto[]>(`${environment.API_BASE_URL}/api/profielen`, {
      params: {
        rrn,
      },
    });
  }

  getProfielDetails(profielId: ProfielId): Observable<ProfielDetailsDto> {
    return this.httpClient.get<ProfielDetailsDto>(
      `${environment.API_BASE_URL}/api/profielen/${profielId}`,
    );
  }

  activeerProfiel(profielId: ProfielId) {
    return this.httpClient.put<void>(
      `${environment.API_BASE_URL}/api/profielen/${profielId}/activeer`,
      {},
    );
  }

  deactiveerGebruiker(profielId: ProfielId) {
    return this.httpClient.put<void>(
      `${environment.API_BASE_URL}/api/profielen/${profielId}/deactiveer`,
      {},
    );
  }
}
