import { Injectable, Injector, NgZone } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class GlobalHttpErrorHandler implements HttpInterceptor {
  constructor(
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.ngZone.run(() => {
            this.injector.get(AuthService).retrieveSessionUser();
          });
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }
}
