import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FileId } from 'parkour-beheer-app-dto';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  getDownloadLink(fileId: FileId): string {
    return `${environment.API_BASE_URL}/api/file/${fileId}`;
  }
}
