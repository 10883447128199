import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Button } from 'primeng/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { ReplaySubject, Subject } from 'rxjs';
import { ProbleemDto, ProbleemId } from 'parkour-beheer-app-dto';
import { AsyncPipe, DatePipe } from '@angular/common';
import { getMeldingTypeTranslationKey } from '../../../shared/utils';
import { PageLayoutComponent } from '../../../shared/ui/page-layout/page-layout.component';
import { MeldingenService } from '../../services/meldingen.service';
import { DefaultMeldingContentComponent } from '../../components/default-melding-content/default-melding-content.component';
import { MeldingInfoComponent } from '../../components/melding-info/melding-info.component';
import { BerichtMeldingContentComponent } from '../../components/bericht-melding-content/bericht-melding-content.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MeldingFormService } from '../../services/melding-form.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ChangeHistoryComponent } from '../../../shared/ui/change-history/change-history.component';

export type MaskingFormGroup = FormGroup<{
  gemaskeerd: FormControl<boolean>;
}>;

export type MeldingForm = FormGroup<{
  maskingSection: MaskingFormGroup;
}>;

@Component({
  templateUrl: './detail.page.html',
  standalone: true,
  imports: [
    PageLayoutComponent,
    TranslateModule,
    Button,
    RouterLink,
    TagModule,
    AsyncPipe,
    DatePipe,
    DefaultMeldingContentComponent,
    MeldingInfoComponent,
    BerichtMeldingContentComponent,
    ChangeHistoryComponent,
    ReactiveFormsModule,
    ToastModule,
  ],
  providers: [MessageService],
})
export class DetailPage implements OnInit {
  melding$: Subject<ProbleemDto> = new ReplaySubject<ProbleemDto>(1);

  meldingForm!: MeldingForm;

  protected readonly getMeldingTypeTranslationKey = getMeldingTypeTranslationKey;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly meldingenService: MeldingenService,
    private readonly formBuilder: FormBuilder,
    private readonly meldingFormService: MeldingFormService,
    private readonly messageService: MessageService,
  ) {}

  ngOnInit() {
    this.getMelding();
  }

  getMelding() {
    const meldingId = this.activatedRoute.snapshot.paramMap.get('id') as ProbleemId;

    this.meldingenService.getMelding(meldingId).subscribe({
      next: (melding) => {
        this.melding$.next(melding);

        if (melding.type === 'BERICHT') {
          this.meldingForm = this.formBuilder.nonNullable.group({
            maskingSection: this.meldingFormService.createMaskingFormGroup(melding.gemaskeerd),
          });
        }
      },
      error: (e) => {
        throw e;
      },
    });
  }

  onSubmit(meldingId: ProbleemId) {
    const formValues = this.meldingForm.getRawValue();

    if (this.meldingForm.valid) {
      this.meldingenService
        .updateMelding(meldingId, {
          gemaskeerd: formValues.maskingSection.gemaskeerd,
        })
        .subscribe({
          complete: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Aanpassingen bewaard',
            });

            this.getMelding();
          },
          error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Er ging iets fout bij het bewaren',
            });

            throw e;
          },
        });
    }
  }
}
