<div class="px-4 pt-4 pb-1 flex flex-row items-center justify-between gap-x-4">
  <span class="text-center typo-h5 text-p-100">{{ 'title' | translate }}</span>

  <button
    (click)="onLogoutClick()"
    class="p-button p-button-secondary p-button-outlined p-button-sm"
  >
    <span class="p-button-label">{{ 'buttons.logout' | translate }}</span>
  </button>
</div>

<nav>
  @if (items$ | async; as items) {
    <p-tabMenu [model]="items" />
  }
</nav>
