<div class="bg-n-100 rounded py-2 px-3 flex items-center justify-between gap-x-2">
  <div class="text-n-1000 flex gap-x-2 items-center">
    <span class="pi pi-file"></span>

    <span class="break-text">{{ decodeURIComponent(bericht.bestand.filename) }}</span>
  </div>

  <span class="typo-body variant-body-small text-n-1000/60 self-end">{{
    bericht.timestamp | date: 'HH:mm'
  }}</span>
</div>
