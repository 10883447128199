<article>
  <p-panel>
    <ng-template pTemplate="header">
      <h1 class="typo-h4">{{ 'notification-page.content-title' | translate }}</h1>
    </ng-template>

    <div class="space-y-2">
      <h2 class="typo-body variant-body-fat">{{ 'notification-page.description' | translate }}</h2>

      <p>{{ melding.beschrijving }}</p>
    </div>
  </p-panel>
</article>
