import { Component, Input } from '@angular/core';
import { AttachmentBerichtDto, SpraakBerichtDto } from 'parkour-beheer-app-dto';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'file-message',
  templateUrl: './file-message.component.html',
  standalone: true,
  imports: [DatePipe],
})
export class FileMessageComponent {
  @Input({ required: true }) bericht!: AttachmentBerichtDto | SpraakBerichtDto;
  protected readonly decodeURIComponent = decodeURIComponent;
}
