import { Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TranslateModule } from '@ngx-translate/core';
import { BerichtProbleemDto } from 'parkour-beheer-app-dto';
import { getMeldingReasonTranslationKey } from '../../../shared/utils';
import { TextMessageComponent } from '../text-message/text-message.component';
import { ProofCardComponent } from '../proof-card/proof-card.component';
import { FileMessageComponent } from '../file-message/file-message.component';
import { MaskingFormGroup } from '../../pages/detail/detail.page';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'bericht-melding-content',
  templateUrl: './bericht-melding-content.component.html',
  standalone: true,
  imports: [
    PanelModule,
    TranslateModule,
    TextMessageComponent,
    ProofCardComponent,
    FileMessageComponent,
    RadioButtonModule,
    ReactiveFormsModule,
  ],
})
export class BerichtMeldingContentComponent {
  @Input({ required: true }) melding!: BerichtProbleemDto;
  @Input({ required: true }) form!: MaskingFormGroup;
  protected readonly getMeldingReasonTranslationKey = getMeldingReasonTranslationKey;
}
