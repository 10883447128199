import { inject, NgModule } from '@angular/core';
import { CanMatchFn, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OverviewPage as meldingenOverviewPage } from './meldingen/pages/overview/overview.page';
import { DetailPage as meldingenDetailPage } from './meldingen/pages/detail/detail.page';
import { OverviewPage as gebruikersOverviewPage } from './profiel/pages/overview/overview.page';
import { DetailPage as gebruikersDetailPage } from './profiel/pages/detail/detail.page';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { map, take } from 'rxjs';
import { AuthService } from './auth/services/auth.service';
import { LoginPage } from './auth/pages/login-page/login.page';

const userNotLoggedIn: CanMatchFn = () =>
  inject(AuthService).user$.pipe(
    take(1),
    map((user) => user.rol === 'ANONIEM'),
  );

const routes: Routes = [
  {
    path: '**',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginPage,
      },
    ],
    canMatch: [userNotLoggedIn],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'meldingen',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'meldingen',
        component: meldingenOverviewPage,
      },
      {
        path: 'meldingen/:id',
        component: meldingenDetailPage,
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'gebruikers',
        component: gebruikersOverviewPage,
      },
      {
        path: 'gebruikers/:id',
        component: gebruikersDetailPage,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
