import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TopBarComponent } from '../../ui/top-bar/top-bar.component';

@Component({
  templateUrl: './main-layout.component.html',
  standalone: true,
  imports: [RouterOutlet, TopBarComponent],
})
export class MainLayoutComponent {}
